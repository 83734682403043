<template>
  <el-row :gutter="10" class="panel-group" type="flex" justify="space-around">
    <el-col :xs="12" :sm="12" :lg="4" class="card-panel-col">
      <!-- <div class="card-panel" @click="handleSetLineChartData('newVisitis')"> -->
      <div class="card-panel" style="background: #758DED;border-top-left-radius:10px;border-top-right-radius:10px;border-bottom-left-radius:10px;border-bottom-right-radius:10px;" @click="skip('one')">
        <div class="card-panel-icon-wrapper icon-people">
          <!-- <svg-icon icon-class="peoples" class-name="card-panel-icon" /> -->
          <i class="el-icon-date" style="font-size: 40px;"></i>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
           待办任务
          </div>
          <span class="card-panel-num">{{waitDoNumber}}</span>
          <!-- <count-to :start-val="0" :end-val="7" :duration="2600" class="card-panel-num" /> -->
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="4" class="card-panel-col">
      <!-- <div class="card-panel" @click="handleSetLineChartData('messages')"> -->
       <div class="card-panel" style="background: #27BB97;border-top-left-radius:10px;border-top-right-radius:10px;border-bottom-left-radius:10px;border-bottom-right-radius:10px;" @click="skip('two')">
        <div class="card-panel-icon-wrapper icon-message">
          <!-- <svg-icon icon-class="message" class-name="card-panel-icon" /> -->
          <i class="el-icon-date" style="font-size: 40px;"></i>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            待阅任务
          </div><span class="card-panel-num">{{waitReadNumber}}</span>
          <!-- <count-to :start-val="0" :end-val="2" :duration="3000" class="card-panel-num" /> -->
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="4" class="card-panel-col">
      <!-- <div class="card-panel" @click="handleSetLineChartData('purchases')"> -->
       <div class="card-panel" style="background: #F27515;border-top-left-radius:10px;border-top-right-radius:10px;border-bottom-left-radius:10px;border-bottom-right-radius:10px;" @click="skip('three')">
        <div class="card-panel-icon-wrapper icon-money">
          <!-- <svg-icon icon-class="money" class-name="card-panel-icon" /> -->
          <i class="el-icon-date" style="font-size: 40px;"></i>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            待审核任务
          </div>
          <span class="card-panel-num">{{waitApprovalNumber}}</span>
          <!-- <count-to :start-val="0" :end-val="10" :duration="3200" class="card-panel-num" /> -->
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="4" class="card-panel-col">
      <!-- <div class="card-panel" @click="handleSetLineChartData('shoppings')"> -->
      <div class="card-panel" style="background: #F08CBE;border-top-left-radius:10px;border-top-right-radius:10px;border-bottom-left-radius:10px;border-bottom-right-radius:10px;" @click="skip('four')">
        <div class="card-panel-icon-wrapper icon-shopping">
          <!-- <svg-icon icon-class="shopping" class-name="card-panel-icon" /> -->
          <i class="el-icon-date" style="font-size: 40px;"></i>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            已完成任务
          </div>
          <span class="card-panel-num">{{complatedNumber}}</span>
          <!-- <count-to :start-val="0" :end-val="20" :duration="3600" class="card-panel-num" /> -->
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="4" class="card-panel-col">
      <!-- <div class="card-panel" @click="handleSetLineChartData('newVisitis')"> -->
      <div class="card-panel" style="background: #67C23A;border-top-left-radius:10px;border-top-right-radius:10px;border-bottom-left-radius:10px;border-bottom-right-radius:10px;" @click="skip('guanzhu')">
        <div class="card-panel-icon-wrapper icon-people">
          <!-- <svg-icon icon-class="peoples" class-name="card-panel-icon" /> -->
          <i class="el-icon-star-off" style="font-size: 40px;"></i>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
           关注任务
          </div>
          <span class="card-panel-num">{{collectNumber}}</span>
          <!-- <count-to :start-val="0" :end-val="7" :duration="2600" class="card-panel-num" /> -->
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>

import CountTo from 'vue-count-to'
import {GetHomeOverviewNumberData} from '../../../../api/oa'
export default {
  components: {
    CountTo
  },
  data(){
    return{
      waitDoNumber: 0,            //待办任务
      waitReadNumber: 0,          //待阅任务
      waitApprovalNumber: 0,      //待审核任务
      complatedNumber: 0,     //今日已完成任务
      collectNumber: 0     //关注任务
    }
  },
  methods: {
    handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type)
    },
    getCounts(){
      var user = JSON.parse(window.localStorage.user);
      GetHomeOverviewNumberData({userCode: user.sub}).then(res => {
            this.waitDoNumber = res.data.response.waitDoNumber;
            this.waitReadNumber = res.data.response.waitReadNumber;
            this.waitApprovalNumber = res.data.response.waitApprovalNumber;
            this.complatedNumber = res.data.response.complatedNumber;
            this.collectNumber = res.data.response.collectNumber;
      })
    },
    skip(val){
      if(val == "one"){
        this.$router.push({path:'/Task/WaitDoTask'});
      }else if(val == "two"){
        this.$router.push({path:'/Task/WaitReadTask'});
      }else if(val == "three"){
        this.$router.push({path:'/Task/WaitApproval'});
      }else if(val =="guanzhu"){
        this.$router.push({path:'/Task/MyCollectTask'});
      }else{
        this.$router.push({path:'/Task/ComplatedTask'});
      }
    }
  },
  mounted(){
    this.getCounts();
  }
}
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    // &:hover {
    //   .card-panel-icon-wrapper {
    //     color: #fff;
    //   }

    //   .icon-people {
    //     background: #40c9c6;
    //   }

    //   .icon-message {
    //     background: #36a3f7;
    //   }

    //   .icon-money {
    //     background: #f4516c;
    //   }

    //   .icon-shopping {
    //     background: #34bfa3
    //   }
    // }

    .icon-people {
      color: #96B0FD;
    }

    .icon-message {
      color: #54DBBB;
    }

    .icon-money {
      color: #FBC5A3;
    }

    .icon-shopping {
      color: #FAA4D1
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: #fff;
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
        color: #fff;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>
